import React from 'react';

const NotFound = (props) => {
  return (
    <div className='flex justify-center flex-col items-center space-y-8 z-50'>
      <h1 className='mb-5 text-5xl font-bold'>404 Not Found</h1>
      <img src='https://i.gifer.com/7VE.gif' alt='404 not found' />
    </div>
  );
};

export default NotFound;
