import { createContext, useState } from 'react';

export const SessionContext = createContext({});

export function SessionContextProvider(props) {
  const [session, setSession] = useState({
    roomCode: '',
    data: [{ name: '', text: '' }],
  });

  return (
    <SessionContext.Provider value={{ session, setSession }}>
      {props.children}
    </SessionContext.Provider>
  );
}
