import React, { useState, useEffect, useContext } from 'react';
//this is the hook to extract the url in the browser
import { useLocation } from 'react-router-dom';
import { io } from 'socket.io-client';
import { RoomContext } from '../../../context/RoomCodeContext';
import { SessionContext } from '../../../context/SessionContext';
import axios from 'axios';

const RoomSession = (props) => {
  // Dictionary API
  const [showModal, setShowModal] = React.useState(false);
  const [dictionary, setDictionary] = useState({});
  const getWord = async (word) => {
    console.log('word', word);
    try {
      let url = `https://dictionaryapi.com/api/v3/references/sd4/json/${word}?key=9df4ffc0-ab26-447a-bc81-6d59b146f35e`;
      let wordRes = await axios.get(url);

      // let gifApiRes = await axios.post(
      //   'https://48hfhabz0l.execute-api.us-east-1.amazonaws.com/dev/prononciation',
      //   {
      //     word,
      //   }
      // );

      // let gifApiRes = await axios.post(
      //   'https://48hfhabz0l.execute-api.us-east-1.amazonaws.com/dev/prononciation',
      //   { word: 'word' },
      //   {
      //     headers: {
      //       'content-type': 'text/json',
      //     },
      //   }
      // );
      // let gif = gifApiRes.data.gif;
      // console.log('this is the gif', gif);
      let firstDef = wordRes.data[0];
      console.log(firstDef);
      setDictionary({
        word: word,
        // gif: gif,
        isPopupOpen: true,
        definition: firstDef.shortdef[0],
        ipa: firstDef.hwi.prs[0].mw,
        audioLink: `https://media.merriam-webster.com/audio/prons/en/us/mp3/${firstDef.hwi.prs[0].sound.audio.substring(
          0,
          1
        )}/${firstDef.hwi.prs[0].sound.audio}.mp3`,
      });

      console.log(firstDef.shortdef[0]);
      console.log(firstDef.hwi.prs);
    } catch (err) {
      console.log(err);
    }
  };
  // ^^^^Dictionary API
  const location = useLocation();
  // const [name, setName] = useState(localStorage.getItem('name'));
  // const handleChange = (e) => {
  //   setName(e.target.value);
  // };
  //this is basically saying i need to use setRoomCode from the parent that is providing the state
  const { roomCode } = useContext(RoomContext);
  const { session, setSession } = useContext(SessionContext);
  // let textdoc = 'asdfasdf';
  const [socket, setSocket] = useState(null);
  const [name, setName] = useState(localStorage.getItem('name'));
  const handleChange = (e) => {
    setName(e.target.value);
    console.log('name>>>>>', name);
  };

  // //socket connection
  useEffect(() => {
    setSocket(io('https://littlereadingrays.herokuapp.com/'));
    //local server
    // setSocket(io('http://localhost:3001'));
  }, []);

  //this effect establishes the connection
  useEffect(() => {
    //if socket is not set it simply returns and ignore the following code
    if (!socket) return;
    console.log(`above on connect you connected with id:${socket.id}`);
    socket.on('connect', () => {
      console.log(`you connected with id:${socket.id}`);
      //emit to send room num back to the sever by grabing the end of the URL
      let roomNum = location.pathname.slice(-5);
      socket.emit('enter-room', {
        roomCode: roomNum,
        //sends the json of the document or null
        config: session.roomCode === roomNum ? session : null,
      });
      console.log('room global state is>>>>', roomCode);
    });
    //update-event
    socket.on('update-event', (document) => {
      console.log('document recieved from socket>>>', document);
      console.log('attempting to set session>>>');
      setSession(document);
      console.log('NEw session value>>>', session);
    });
    socket.on('invalid-room', () => {
      alert('Sorry Room Not Found');
    });
    socket.on('successful-room-entrance', () => {
      console.log('successful-room-entrance');
    });
  }, [socket]);

  //context menu
  window.addEventListener('contextmenu', function (event) {
    event.preventDefault();
    var contextElement = document.getElementById('context-menu');
    contextElement.style.top = event.offsetY + 'px';
    contextElement.style.left = event.offsetX + 'px';
    contextElement.classList.add('active');
  });
  window.addEventListener('click', function () {
    document.getElementById('context-menu').classList.remove('active');
  });

  return (
    <div>
      {showModal ? (
        <>
          <div
            className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'
            onClick={() => setShowModal(false)}
          >
            <div className='relative w-auto my-6 mx-auto max-w-3xl'>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                {/*header*/}
                <div className='flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t'>
                  <h3 className='text-3xl font-semibold'>
                    {dictionary.word} \{dictionary.ipa}\
                  </h3>
                  <button
                    className='p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none'
                    onClick={() => setShowModal(false)}
                  >
                    <span className='bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none'>
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className='relative p-6 flex-auto flex-row'>
                  <audio src={dictionary.audioLink} controls></audio>
                  {/* <img
                    src={dictionary.gif}
                    alt='gif on how to pronounce'
                    width='100'
                    height='250'
                  ></img> */}
                  <p className='my-4 text-blueGray-500 text-lg leading-relaxed'>
                    Definition: {dictionary.definition}
                  </p>
                  <p className='my-4 text-blueGray-500 text-lg leading-relaxed'>
                    {dictionary.gif}
                  </p>
                </div>
                {/*footer*/}
                <div className='flex items-center justify-center p-6 border-t border-solid border-blueGray-200 rounded-b'>
                  <button
                    className='px-8 py-4 lowercase bg-secondary text-white font-semibold rounded-xl text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={() => setShowModal(false)}
                  >
                    Got it
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      ) : null}
      {name ? (
        <div>
          <h1 className='text-center font-semibold text-xl'>Room:{roomCode}</h1>
          {session.data.length ? (
            <div className='relative prose prose-sm sm:prose lg:prose-lg xl:prose-2xl prose-primary bg-white container rounded-xl mx-auto p-5 z-30'>
              <h2 className='z-50'>{session.data[0].name.split('.', 1)[0]}</h2>
              <p
                className='z-50 whitespace-pre-wrap'
                onMouseUp={() => {
                  getWord(`${window.getSelection().toString()}`);
                  setShowModal(true);
                }}
              >
                {session.data[0].text}
              </p>
            </div>
          ) : (
            <div>loading</div>
          )}
        </div>
      ) : (
        //This is when new users join and they dont have a name in local storage
        <div className='flex justify-center flex-col items-center space-y-8 z-50'>
          <h1>Hello Nice To Meet You. What Is Your Name?</h1>
          <form
            onSubmit={(e) => {
              //not using preventDefault beacuse i do want the page to re render
              e.preventDefault();
              console.log(e);
              setName(e.target[0].value);
              localStorage.setItem('name', e.target[0].value);
              console.log('emmit socket id', socket.id);
              console.log('name', name);
            }}
            action='#'
          >
            <label htmlFor=''>
              <input type='text' name='name' />
            </label>
            <button type='submit'>Enter Room</button>
          </form>
        </div>
      )}

      {/*context menu*/}
      {/* <div id='context-menu'>
        <div class='item'>
          <i class='fa fa-cut'></i> HightLight
        </div>
        <div class='item'>
          <i class='fa fa-clone'></i> Translate
        </div>
        <div class='item'>
          <i class='fa fa-paste'></i> Change Cursor
        </div>
        <div class='item'>
          <i class='fa fa-trash-o'></i> Delete
        </div>
        <hr />
        <div class='item'>
          <i class='fa fa-refresh'></i> Reload
        </div>
        <div class='item'>
          <i class='fa fa-times'></i> Exit
        </div>
      </div> */}
    </div>
  );
};

export default RoomSession;
