import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { About } from './static';

import { Platform, Download, RoomSession, NotFound } from './views';

const Body = () => {
  return (
    <section className='flex-grow'>
      <Routes>
        <Route exact path='/' element={<Platform />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/download' element={<Download />} />
        <Route exact path='/room/:id' element={<RoomSession />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </section>
  );
};

export default Body;
