import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Logo from '../../images/stingray_logo_notext.png';
import HeaderBG from '../../images/layered-waves-header.svg';

const Header = () => {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <div className='relative '>
        <img
          src={HeaderBG}
          className='top-0 z-0 absolute object-fill w-screen'
          alt='Header Background Waves'
        ></img>
      </div>

      <nav className='relative flex flex-wrap items-center justify-between px-2 py-3 mb-10 z-50'>
        <div className='container px-4 mx-auto flex flex-wrap items-center justify-between'>
          <div className='w-1/2 relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start'>
            <img
              src={Logo}
              className='h-16 mr-5 inline-block'
              alt='Reading Ray Logo'
            />
            <Link
              className='text-lg font-bold leading-relaxed inline-block py-2 whitespace-nowrap uppercase text-white'
              to='/'
            >
              Reading Rays
            </Link>
            <button
              className='text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none'
              type='button'
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>
          <div
            className={
              'lg:flex flex-grow items-center' +
              (navbarOpen ? ' flex' : ' hidden')
            }
          >
            <ul className='flex flex-col lg:flex-row list-none lg:ml-auto space-x-12'>
              <li className='nav-item'>
                <Link
                  className='lowercase py-2 flex items-center text-lg font-bold leading-snug text-white hover:opacity-75 tracking-wider'
                  to='/'
                >
                  Platform
                </Link>
              </li>
              {/* <li className='nav-item'>
                <a
                  className='lowercase py-2 flex items-center text-lg  font-bold leading-snug text-white hover:opacity-75 tracking-wider'
                  href='https://www.sunnyside.care/about'
                >
                  About
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
