import React from "react";
import Logo from "../../images/stingray_logo_notext.png";

const Footer = () => {
  return (
    <>
      <footer className="px-4 py-8 mt-10 z-50">
        <div className="container flex flex-wrap items-center justify-center mx-auto space-y-4 ">
          <div className="flex items-center justify-center dark:bg-violet-400">
            <img src={Logo} className="h-8 mr-5" alt="Reading Ray Logo" />

            <span className="dark:text-coolGray-400">Reading Rays © 2021</span>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
