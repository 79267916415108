import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import React, { useState, useContext, useEffect } from 'react';
import { RoomContext } from '../../../context/RoomCodeContext';
import { SessionContext } from '../../../context/SessionContext';

const Platform = (props) => {
  //this is the NEW way of redirecting and messing with the histroy prop
  let navigate = useNavigate();

  const [file, setFile] = useState(null);
  // const [output, setOutput] = useState(null);

  //this is basically saying i need to use setRoomCode from the parent that is providing the state
  const { roomCode, setRoomCode } = useContext(RoomContext);
  const { session, setSession } = useContext(SessionContext);

  console.log('roomcode', roomCode);
  // useEffect(() => {
  //   navigate(`/room/${roomCode}`);
  // }, [output]);
  const handleChange = (e) => {
    setRoomCode(e.target.value);
    console.log('roomcode');
  };

  const submitFile = async (event) => {
    //prvent re render when file is submitted
    event.preventDefault();
    try {
      if (!file) {
        throw new Error('Select a file first!');
      }
      const formData = new FormData();
      formData.append('file', file[0]);
      console.log('>> formData >> ', formData);
      let res = await axios.post(
        // 'https://api.littlereadingrays.com/upload',
        'https://littlereadingrays.herokuapp.com/upload',
        // 'http://localhost:3001/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      // setOutput(res.data);

      // console.log('roomCode>>>', res.data.roomCode);
      // console.log('name and parsed text>>>', res.data.data[0]);
      // console.log('res.data.data[0].text', res.data.data[0].text);
      // console.log('output', output);
      // localStorage.setItem('room', res.data.roomCode);
      // localStorage.setItem('output', output);
      //setting the gb state when uploaded file comes back as a json

      //sets global state roomCode to be equal to res.data.roomCode
      setRoomCode(res.data.roomCode);
      console.log('roomcode', roomCode);
      setSession(res.data);
      console.log('session', session);
      console.log('response', res.data);

      //navigate to room
      navigate(`/room/${res.data.roomCode}`);
    } catch (error) {
      // handle error
      console.log(error);
    }
  };

  return (
    <>
      {/* BG Design */}

      <div className='absolute bottom-0 h-80 z-0 w-screen footerBG'></div>

      <div className='flex justify-center flex-col items-center space-y-8 z-50'>
        <h1 className=' font-semibold text-black text-center text-6xl z-50'>
          Welcome to Reading Rays
        </h1>

        <h2 className='text-xl text-black text-center z-50'>
          Engage your classroom and <br /> sessions remotely
        </h2>

        <form
          onSubmit={submitFile}
          className='container flex flex-col items-center space-y-8 z-50'
        >
          <label className='text-white text-md font-semibold -mb-14 bg-secondary rounded-xl px-5 py-4 z-50'>
            Upload text documents, word documents, or pdf files
          </label>

          <div className='bg-white w-5/12 h-80 rounded-xl mx-auto p-5'>
            {file ? (
              <label className='text-3xl w-full h-full border-2 border-dashed border-purple-400 rounded-xl flex flex-col items-center justify-center'>
                <input
                  type='file'
                  onChange={(event) => setFile(event.target.files)}
                  className='hidden'
                />
                {file[0].name}
              </label>
            ) : (
              <label className='text-3xl w-full h-full border-2 border-dashed border-purple-400 rounded-xl flex flex-col items-center justify-center'>
                <input
                  type='file'
                  onChange={(event) => setFile(event.target.files)}
                  className='hidden'
                />
                Click here to upload
              </label>
            )}
          </div>
          {/* <div className='flex justify-center flex-col items-center space-y-8 z-50'>
            <h1>Have a room code?</h1>
            <form
              onSubmit={(e) => {
                //not using preventDefault beacuse i do want the page to re render
                e.preventDefault();
                navigate(`/room/${roomCode}`);
              }}
            >
              <label htmlFor=''>
                <input type='text' name='roomCode' onChange={handleChange} />
              </label>
              <button
                type='submit'
                className='flex flex-row items-start p-4 bg-secondary text-white font-semibold rounded-xl z-50'
              >
                Enter Room
              </button>
            </form>
          </div> */}

          <h2 className='text-xl text-black text-center z-50'>
            had a previous session? <br /> drop your session file and we'll load
            it in!
          </h2>

          <button
            type='submit'
            className='flex flex-row items-start p-4 bg-secondary text-white font-semibold rounded-xl z-50'
          >
            start session
          </button>
        </form>
      </div>
    </>
  );
};

export default Platform;
