import { createContext, useState } from 'react';

export const RoomContext = createContext({});

export function RoomContextProvider(props) {
  const [roomCode, setRoomCode] = useState(null);

  return (
    <RoomContext.Provider value={{ roomCode, setRoomCode }}>
      {props.children}
    </RoomContext.Provider>
  );
}
