import React from "react";

const Download = () => {
  return (
    <>
      <h1>Download Page</h1>
    </>
  );
};

export default Download;
