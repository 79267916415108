import React, { useState } from 'react';
import Header from './components/header/Header';
import Body from './components/body/Body';
import Footer from './components/footer/Footer';
//experamental context
import { RoomContextProvider } from './context/RoomCodeContext';
import { SessionContextProvider } from './context/SessionContext';

function App() {
  // const [roomCode, setRoomCode] = useState({});
  // const [session, setSession] = useState({
  //   roomCode: '',
  //   data: [],
  // });
  // const [name, setName] = useState(null);
  return (
    <div className='App bg-primary flex flex-col min-h-screen'>
      <Header />
      <RoomContextProvider>
        <SessionContextProvider>
          <Body />
        </SessionContextProvider>
      </RoomContextProvider>
      <Footer />
    </div>
  );
}

export default App;
